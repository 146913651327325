import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AngularFireModule } from "@angular/fire/compat";
// import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import {
  AngularFireFunctionsModule,
  REGION,
} from "@angular/fire/compat/functions";
import { SwiperModule } from "swiper/angular";
import { HttpClientModule } from "@angular/common/http"

import { environment } from "src/environments/environment";
// const { initializeApp } = require("firebase/app");
// const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require("firebase/app-check");

// const app = initializeApp({
//   apiKey: "AIzaSyDSgN69u0ib1T5YQwNiD5AihMdMnmju4xY",
//   authDomain: "primeclap-bbb0a.firebaseapp.com",
//   projectId: "primeclap-bbb0a",
//   storageBucket: "primeclap-bbb0a.appspot.com",
//   messagingSenderId: "635694781275",
//   appId: "1:635694781275:web:3691bf664a4691fe3b407e",
//   measurementId: "G-3P9LR39VDT",
// });

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("fewef"),
//   isTokenAutoRefreshEnabled: false,
// });

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    SwiperModule,
    HttpClientModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: "asia-south1" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
