import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "startup",
    loadChildren: () =>
      import("./pages/startup/startup.module").then((m) => m.StartupPageModule),
  },
  {
    path: "",
    redirectTo: "/startup",
    pathMatch: "full",
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./pages/auth/sign-in/sign-in.module").then(
        (m) => m.SignInPageModule
      ),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./pages/auth/sign-up/sign-up.module").then(
        (m) => m.SignUpPageModule
      ),
  },
  {
    path: "forget-password",
    loadChildren: () =>
      import("./pages/auth/forget-password/forget-password.module").then(
        (m) => m.ForgetPasswordPageModule
      ),
  },
  {
    path: "company-register",
    loadChildren: () =>
      import("./pages/company-register/company-register.module").then(
        (m) => m.CompanyRegisterPageModule
      ),
  },

  {
    path: "sidemenu",
    loadChildren: () =>
      import("./pages/dashboard/sidemenu/sidemenu.module").then(
        (m) => m.SidemenuPageModule
      ),
  },

  {
    path: "movie-sidemenu",
    loadChildren: () =>
      import(
        "./pages/movie_dashboard/movie-sidemenu/movie-sidemenu.module"
      ).then((m) => m.MovieSidemenuPageModule),
  },
  {
    path: "edit-movie",
    loadChildren: () =>
      import("./pages/dashboard/edit-movie/edit-movie.module").then(
        (m) => m.EditMoviePageModule
      ),
  },
  {
    path: "location-manager",
    loadChildren: () =>
      import(
        "./pages/movie_dashboard/pre_production/location/location-manager/location-manager.module"
      ).then((m) => m.LocationManagerPageModule),
  },
  {
    path: "shot-manager",
    loadChildren: () =>
      import(
        "./pages/movie_dashboard/production/shot-manager/shot-manager.module"
      ).then((m) => m.ShotManagerPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
